var rootRouter = []

export function generateRootRouter(data, roles) {
    rootRouter = []
    if (data.length > 0) {
        routerMap(data, roles)
        return rootRouter
    } else {
        return []
    }
}

export const routerMap = (list, roles) => {
    list.forEach(item => {
        if (item.url && item.url != '#' && roles.indexOf(item.id) !== -1) {
            rootRouter.push(generator(item))
            // if (item.remark == 1) {
                item.children.forEach(item1 => {
                    rootRouter.push(generator(item1))
                })
            // }
        }
        if (item.children && item.children.length > 0) {
            routerMap(item.children, roles)
        }
    })
}


export const generator = (row) => {
    const url = row.url.includes('/shop') ? `${row.url}/index` : row.url
    return {
        path: row.url,
        component: (resolve) => {
            require(['@/pages' + url + '.vue'], resolve);
        },
        meta: {
            name: row.name, father: onFather(row), background: row.background == 1 ? true : false
        }
    }
}

export const onFather = (row) => {
    let father = ''
    let num1 = 0
    let num2 = 0
    for (let i = 0; i < row.url.length; i++) {
        if (num1 == 0) {
            if (row.url[i] == '/') {
                num1 = i + 1
            }
        } else {
            if (row.url[i] == '/') {
                num2 = i
            }
        }
    }
    father = row.url.substring(num1, num2)
    return father
}



