import { Http } from '@/assets/js/http'
import store from '../store'
import router from '../router'
import { generateRootRouter } from '@/router/async-router'

export const onInitial = (state, data) => {
    Http({
        url: 'InstallSite',
        data: {}
    }).then((res) => {
        if (res.code == 1) {
            document.title = res.data.zikong_name

            var currentIcon = window.global_config.BASE_IMG_URL + res.data.user_backend_url
            var link = document.querySelector('link[rel*="icon"]');
            link.href = currentIcon;

            store.commit('INITIAL_INFO', res.data)
        }
    })
}
//查询当前登录用户详情的方法
export const onRoles = (state, data) => {
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    let store_id=localStorage.getItem("store_id")
    return new Promise(resolve => {
        Http({
            url: 'userDetails',
            data: { id: userinfo.id ,store_id:store_id}
        }).then((res) => {
            if (res.code == 1) {
                localStorage.setItem("userDetails",JSON.stringify(res.data))
                if (res.data.rules_data_ids && res.data.rules_data_ids.length > 0) {
                    // let ids = []
                    // for(let i=1;i<80;i++){
                    //     ids.push(i)
                    // }
                    // store.commit('ROLES_LIST', ids)
                    // resolve(ids)
                    store.commit('ROLES_LIST', res.data.rules_data_ids)  //当前用户角色的权限ID
                    resolve(res.data.rules_data_ids)
                } else {
                    resolve('false')
                }
            } else {
                // this.$message.error(res.msg)
                resolve('false')
            }
        })
    })
}

//查询初始菜单的方法
export const onMenu = (state, data) => {
    return new Promise(resolve => {
        Http({
            url: 'listMenu',
            data: {}
        }).then((res) => {
            if (res.code == 1) {
                if (res.data.length > 0) {
                    store.commit('INITIAL_MENU', res.data)
                    resolve(res.data)
                } else {
                    resolve('false')
                }
            } else {
                resolve('false')
            }
        })
    })
}

export const generateRoutes = (state, data) => {
    return new Promise(resolve => {
        let routerList = []
        if (data.menuList.length > 0 && data.roles.length > 0) {
            routerList = generateRootRouter(data.menuList, data.roles)
            const rootRouter = [
                {
                    title: "首页", url: "/", father: "/", show: true,
                    img1: require("@/assets/image/nav/nav1_1.png"),
                    img2: require("@/assets/image/nav/nav1_2.png"),
                },
                ...filterAsyncRoutes(data.menuList, data.roles)
            ]
            store.commit('MENU_LIST', rootRouter)
        }
        resolve(routerList)
    })
}

export function filterAsyncRoutes(routes, roles) {
    return routes.map((item, index) => {
        let currentRouter = {}
        if (item.status == 'normal') {
            currentRouter = {
                title: item.name,
                show: roles.indexOf(item.id) !== -1 ? true : false,
                url: item.url && item.url != '#' ? item.url : '',
                father: item.father ? item.father : ''
            }
            if (item.pid == 0) {
                currentRouter.img1 = require('@/assets/image/nav/nav' + (index + 2) + '_1.png')
                currentRouter.img2 = require('@/assets/image/nav/nav' + (index + 2) + '_2.png')
                if (item.name == '专属报表' && roles.indexOf(item.id) !== -1) {
                    store.commit('SQL_SHOW', index)
                }
            }
            if (item.children && item.children.length > 0) {
                currentRouter.children = filterAsyncRoutes(item.children, roles)
            }
            if (item.url == '/forms/sqlPages' && roles.indexOf(item.id) !== -1) {
                store.commit('SQL_SHOW', index)
            }
        }
        return currentRouter
    })
}

export const getNews = (state, data) => {
    Http({
        url: 'NewsReadCount',
        data: {}
    }).then((res) => {
        if (res.code == 1) {
            store.commit('NEWSREAD_COUNT', res.data.NewsReadCount)
            store.commit('NEWSREAD_List', res.data.NewsReadList)
        }
    })
}




