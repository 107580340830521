import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./style.css";

import './permission' // permission control

import '@/assets/css/variables.scss'

import Element from 'element-ui';
import '@/assets/css/element-variables.scss'
Vue.use(Element, { size: 'small', zIndex: 3000 });
// import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

//域名
import { Config } from './assets/js/config'
Vue.prototype.$Config = Config

// 引入请求
import { Http } from './assets/js/http'
Vue.prototype.$Http = Http

import { delTagList } from './assets/js/deltag'
Vue.prototype.$delTag = delTagList

import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)

import mzUI from '@/components/mzUI'
Vue.use(mzUI)
import mixins from '@/assets/js/mixins'
Vue.mixin(mixins)

import Print from './assets/js/print'
Vue.use(Print)

//富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

import base from './assets/js/utils.js'
Vue.prototype.$base = base


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
