import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import { Config } from './config'
import router from '../../router'
import store from '../../store'
import { Message } from 'element-ui';

import qs from 'qs'
Vue.use(VueAxios, Axios)


let isLogin = true; //防止多次跳转登录页面
export function Http(params) {
    return new Promise((resolve, reject) => {
        var options = {};
        options.method = params.type ? params.type : 'POST';
        options.headers = {
            'Accept': 'application/json',
            'content-type': options.method === 'POST' ? 'application/x-www-form-urlencoded' : options.method === 'IMG' ? 'multipart/form-data' : 'application/json'
        };
        options.url = Config.http_url + Config.http_api[params.url];
        if (options.method === 'POST') {
            options.data = params.data ? params.data : {};
        } else {
            options.params = params.data ? params.data : {};
        }
        if (params.responseType){
            options.responseType=params.responseType
        }

        let $token = ''
        if (params.token == undefined || params.token) {
            let shop_code = localStorage.getItem("shop_code");
            $token = localStorage.getItem("token");
            if ($token || params.token1) {
                if (options.method === 'POST') {
                    options.data.token = $token
                    options.data.shop_code = shop_code
                    if(options.data.store_id != 'no'){
                        options.data.store_id = options.data.store_id ? options.data.store_id : store.state.store_id
                    }else{
                        options.data.store_id = ''
                    }
                    options.data = qs.stringify(options.data)
                } else {
                    options.params.token = $token
                    options.params.shop_code = shop_code
                    options.params.store_id = options.params.store_id ? options.params.store_id : store.state.store_id
                }
            }
        }
        Axios(options).then(res => {
            if (res.status == 200) {
                resolve(res.data);
            } else {
                resolve({});
            }
        }, err => {
            if (err.response.status === 401) {
                if (err.response.data.msg=='Login Timeout'){
                    Message({
                        message: '登录超时,请重新登录~',
                        type: 'warning'
                    })
                }
                Onlogin()
            } else if (err.response.status === 500) {
                Message({
                    message: '服务器异常请联系管理人员~',
                    type: 'warning'
                })
                resolve({});
            } else {
                Message({
                    message: '网络超时~',
                    type: 'warning'
                })
                resolve({});
            }
            if (err.response.status === 403) {
                // handle server forbidden error
            }
        })
    })
}

//清除缓存去登录页面
function Onlogin() {
    if (isLogin) {
        isLogin = false
        localStorage.clear();
        router.push('/login').then(res => {
            isLogin = true
        })
    }
}