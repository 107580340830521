export const ON_SCALE = (state, data) => {
    state.scale = data
}
export const PAGES_WIDTH = (state, data) => {
    state.pagesWidth = data
}
export const PAGES_HEIGHT = (state, data) => {
    state.pagesHeight = data
}
export const STORE_ID = (state, data) => {
    state.store_id = data
}

export const STORE_INFO = (state, data) => {
    state.store_info = data
}

export const TAG_LIST = (state, data) => {
    state.tagList = data
}

export const STORE_LIST = (state, data) => {
    state.store_list = data
}

export const MENU_LIST = (state, data) => {
    state.menu_list = data
}

export const INITIAL_MENU = (state, data) => {
    state.initial_menu = data
}

export const ROLES_LIST = (state, data) => {
    state.roles_list = data
}

export const INITIAL_INFO = (state, data) => {
    state.initial_info = data
}

export const NEWSREAD_COUNT = (state, data) => {
    state.newsReadCount = data
}

export const NEWSREAD_List = (state, data) => {
    state.NewsReadList = data
}

export const SQL_SHOW = (state, data) => {
    state.sqlshow.push(data)
}
