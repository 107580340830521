export const scale = state => state.scale
export const pagesWidth = state => state.pagesWidth
export const pagesHeight = state => state.pagesHeight
export const store_id = state => state.store_id
export const store_info = state => state.store_info
export const store_list = state => state.store_list
export const tagList = state => state.tagList
export const menu_list = state => state.menu_list
export const initial_menu = state => state.initial_menu
export const roles_list = state => state.roles_list
export const initial_info = state => state.initial_info
export const newsReadCount = state => state.newsReadCount
export const NewsReadList = state => state.NewsReadList
export const sqlshow = state => state.sqlshow